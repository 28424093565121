var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("WidgetFrame", {
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(" Task Alerts ")]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            !_vm.canView("PROJECT", ["TASK"]) || !_vm.canView("STAFF")
              ? _c("div", { staticClass: "center-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "entity_selector.error.insufficient_permission_to_show_data"
                        )
                      ) +
                      " "
                  ),
                ])
              : !_vm.loaded
              ? [
                  _c("div", { staticClass: "status-message" }, [
                    _vm._v("Loading..."),
                  ]),
                ]
              : [
                  _c(
                    "div",
                    { staticClass: "settings" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          model: {
                            value: _vm.showWarnings,
                            callback: function ($$v) {
                              _vm.showWarnings = $$v
                            },
                            expression: "showWarnings",
                          },
                        },
                        [_vm._v("Show warnings")]
                      ),
                      _c(
                        "b-form-checkbox",
                        {
                          model: {
                            value: _vm.showCompleted,
                            callback: function ($$v) {
                              _vm.showCompleted = $$v
                            },
                            expression: "showCompleted",
                          },
                        },
                        [_vm._v("Show completed tasks")]
                      ),
                    ],
                    1
                  ),
                  _vm.loaded
                    ? _c("TaskAlertList", {
                        attrs: { tasks: _vm.alertTasks },
                        on: { editTask: _vm.editTask },
                      })
                    : _vm._e(),
                ],
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }